<template>

      <div style="align-items: flex-start; justify-content: center; display: flex; width: 100%; margin-top:30px;">



              <b-card class="mb-0 col-lg-6 col-md-10 col-sm-12 col-xs-12">
                <b-link class="brand-logo">
                  <h2 class="brand-text text-primary text-center ml-1"><vuexy-logo/><strong class="ml-1">Mymovingway</strong></h2>
                </b-link>
              <b-card-title class="mb-1 text-center" v-if="$i18n.locale === 'tr'">
                      Firmasına Müşteri Olarak Katıl
              </b-card-title>
              <b-form @submit.prevent>
                <b-row>
                    <!-- studio adı-->
                    <b-col cols="12">
                        <b-form-group
                          :label="$t('Facility Name')"
                          label-for="company_name"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="ItalicIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="company_name"
                              name="company_name"
                              type="text"
                              :placeholder="$t('Facility Name')"
                              v-model="studio_data.company_name"
                                @blur="$v.studio_data.company_name.$touch()"
                            />

                          </b-input-group>
                          <small v-if="isSubmit && !$v.studio_data.company_name.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                        </b-form-group>

                    </b-col>
                    <!-- studio resmi adı-->
                    <b-col cols="12">

                        <b-form-group
                          :label="$t('Name')"
                          label-for="first_name"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="CheckCircleIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="first_name"
                              name="first_name"
                              type="text"
                              :placeholder="$t('Name')"
                              v-model="studio_data.first_name"
                              @blur="$v.studio_data.first_name.$touch()"
                            />
                          </b-input-group>
                          <small v-if="isSubmit && !$v.studio_data.first_name.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                        </b-form-group>
                    </b-col>

                    <!-- studio resmi adı-->

                      <b-col cols="12">
                        <b-form-group
                          :label="$t('Surname')"
                          label-for="Surname"
                        >
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="CheckCircleIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                              id="last_name"
                              name="last_name"
                              type="text"
                              :placeholder="$t('Surname')"
                              v-model="studio_data.last_name"
                              @blur="$v.studio_data.last_name.$touch()"
                            />
                          </b-input-group>
                          <small v-if="isSubmit && !$v.studio_data.last_name.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                        </b-form-group>
                    </b-col>

                    <!-- Telefon Numarası-->
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Phone Number')"
                        label-for="phone-number"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="PhoneIcon" />
                          </b-input-group-prepend>
                          <input
                            class="form-control"
                            id="phone-number"
                            name="phone-number"
                            :placeholder="$t('Phone Number')"
                            type="number"
                            maxlength="20"
                            v-model="studio_data.phone_number"
                            @blur="$v.studio_data.phone_number.$touch()"
                          />
                        </b-input-group>
                            <small v-if="isSubmit && !$v.studio_data.phone_number.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                            <small v-if="isSubmit && !$v.studio_data.phone_number.numeric" class="form-text text-danger" >{{$t("The field may only contain numeric characters")}}</small>
                            <small v-if="isSubmit && !$v.studio_data.phone_number.maxLength" class="form-text text-danger" >{{$t("Can contain a maximum of 20 characters")}}</small>
                      </b-form-group>
                    </b-col>


                  <!-- Email Numarası-->

                    <b-col cols="12">
                      <b-form-group
                        :label="$t('Email')"
                        label-for="email"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            name="email"
                            id="email"
                            :placeholder="$t('Email')"
                            v-model="studio_data.email"
                            @blur="$v.studio_data.email.$touch()"
                          />
                        </b-input-group>
                          <small v-if="isSubmit && !$v.studio_data.email.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                          <small v-if="isSubmit && !$v.studio_data.email.email" class="form-text text-danger" >{{$t("The email field must be a valid email")}}</small>
                      </b-form-group>
                    </b-col>

                    <!-- <b-col cols="12">
                      <b-form-group :label="$t('City')" label-for="city">
                        <v-select
                          style="width: 100%; z-index: 450; display: inline-block"
                          :reduce="x => x.CityId"
                          v-model="selectedCity"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="cities"
                          value="CityId"
                          label="CityName"
                          name="city"
                          id="city"
                          @blur="$v.studio_data.city_id.$touch()"
                          ></v-select>

                          <small v-if="isSubmit && !$v.studio_data.city_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>

                      </b-form-group>
                    </b-col> -->

                    <!-- <b-col cols="12">
                      <b-form-group :label="$t('Town')" label-for="town">
                        <v-select
                          style="width: 100%; z-index: 400; display: inline-block"
                          :reduce="x => x.TownId"
                          v-model="selectedTown"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="towns"
                          value="TownId"
                          label="TownName"
                          name="town"
                          @blur="$v.studio_data.town_id.$touch()"
                        >
                        </v-select>
                          <small v-if="isSubmit && !$v.studio_data.town_id.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                      </b-form-group>
                    </b-col> -->

                    <!-- <b-col cols="12">
                      <b-form-group :label="$t('Adress')" label-for="adress">
                        <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="MapIcon" />
                          </b-input-group-prepend>
                        <b-form-textarea
                            v-model="studio_data.adress"
                            id="adress"
                            name="adress"
                            :placeholder="$t('Adress')"
                            rows="1"
                            @blur="$v.studio_data.adress.$touch()"
                          />
                        </b-input-group>
                              <small v-if="isSubmit && !$v.studio_data.adress.required" class="form-text text-danger" >{{$t("This field is required")}}</small>
                      </b-form-group>
                    </b-col> -->

                    <b-col cols="12" class="right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        type="reset"
                        variant="outline-primary"
                        class="mr-1"
                        :to="{ name: 'studio_list' }"
                      >
                        {{ $t("Cancel") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        @click="create"
                      >
                        {{ $t("Save") }}
                      </b-button>
                    </b-col>
                  </b-row>
              </b-form>
            </b-card>
      </div>

</template>

<script>
import {
  BFormTextarea,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BBadge,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  BFormRadio
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {required, numeric, maxLength, email} from "vuelidate/lib/validators";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    Cleave,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BBadge,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    VuexyLogo,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormTextarea,
    BFormRadio
  },
  props: {

  },

  data() {
    return {
      // selectedCity:{
      //   CityId:"00000000-0000-0000-0000-000000000000",
      //   CityName: this.$t('Please choose')
      // },
      // selectedTown:
      // {
      //   TownId:"00000000-0000-0000-0000-000000000000",
      //   TownName: this.$t('Please choose')
      // },
      // towns: [],
      // cities: [],
      studio_data: {
        // CompanyId:"00000000-0000-0000-0000-000000000000",
        // CompanyBranchId:"00000000-0000-0000-0000-000000000000",
        // CountryId:"00000000-0000-0000-0000-000000000000",
        // CityId:"00000000-0000-0000-0000-000000000000",
        // TownId:"00000000-0000-0000-0000-000000000000",
        company_name:null,
        first_name:null,
        last_name:null,
        phone_number:null,
        email:null,
      //  city_id :null,
      //  town_id :null,
      //  address:null
        },
        dataloaded:false,
        selectedIndex: 0,
        isSubmit: false,
    };
  },
  validations:
    {
        studio_data:{

                company_name:{
                  required:required
                },
                first_name:{
                  required:required
                },
                last_name:{
                  required:required
                },
                 email: {
                   email,
                   required
                 },
                 phone_number:{
                    required:required,
                    numeric:numeric,
                    maxLength: maxLength(20)
                 },
                // city_id :{
                   //  required:required
                // },
                // town_id :{
                   //  required:required
                // },

        },
    },


  methods: {
    ...mapActions("mockUser", ["fetchUser"]),


    create() {
      this.isSubmit = true;
       if (this.$v.$invalid)
              return;
          this.$database.DistributorService.self_company_create(
            // this.CompanyId,
            // this.CompanyBranchId,
            // this.CountryId,
            // this.CityId,
            // this.TownId,
            this.studio_data.company_name ,
            this.studio_data.first_name,
            this.studio_data.last_name,
            this.studio_data.phone_number,
            this.studio_data.email
      ).then((res) => {
        console.log(res);
        if (!res.is_success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Action failed!"),
              icon: "XIcon",
              variant: "danger",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Facility Successfully Identified"),
              icon: "CheckIcon",
              variant: "success",
            },
          })
           this.$router.push("/studioList"); //sayfa yönlendirme
        }
      });
    },
    // getCities() {
    //   this.$database.LocationService.list_city_of_the_country().then((res) => {
    //     if (!res.IsSuccess)
    //           return;

    //         this.cities = res.Result;
    //         //  this.studio_data.city_id=res.Result[0].CityId
    //            this.dataloaded=true;
    //         //  this.getTowns();
    //   });
    // },
  },
  watch: {
    //2.kontrol mekanizmam - asenkron inputları izleyerek şu an 20 (max) karakter ile sınırlandırıyorum
    'studio_data.phone_number'(val){
      if(this.studio_data.phone_number.length > 20){
        this.studio_data.phone_number = val.slice(0,20)
      }
    },
    // 'studio_data.tax_code'(val){
    //   if(this.studio_data.tax_code.length > 20){
    //     this.studio_data.tax_code = val.slice(0,20)
    //   }
    // },
    // 'studio_data.companyType'(val){
    //   if(val == 'false'){
    //     this.studio_data.tax_administration = 'Şahıs Şirketi'
    //   }else {
    //     this.studio_data.tax_administration = ''
    //   }
    // },


    // selectedCity(val)
    //  {
    //   if(this.dataloaded===true)
    //   {

    //     this.selectedTown = {};
    //     this.studio_data.city_id = val;

    //     this.towns = [];
    //     this.$database.LocationService.list_towns_of_the_city(
    //       val

    //     ).then((res) => {
    //       if (!res.IsSuccess)
    //           return;

    //           this.towns = res.Result;
    //           this.selectedTown.TownId="00000000-0000-0000-0000-000000000000";
    //           this.selectedTown.TownName="Lütfen Seçim Yapınız";
    //     });
    //   }
    // },
    // selectedTown(val) {
    //   this.studio_data.town_id = val;
    // },
  },
  created()
  {
   // this.getCities();
  },
};
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";
</style>
